import React, { useState } from 'react';
import * as styles from './alt-gegen-neu.module.scss';
import Layout from "../../page-components/layout/layout";
import axios from 'axios';
import { getStrapiUrl } from "../../helper/gsConstants";

const AltGegenNeu = () => {
  const [formState, setFormState] = useState('initial');
  const [formData, setFormData] = useState({
    anrede: '',
    name: '',
    email: '',
    phone: '',
    clubType: '',
    manufacturer: '',
    model: '',
    condition: '',
    gender: '',
    handedness: '',
    loft: '',
    shaftMaterial: '',
    shaftFlex: '',
    shaftLength: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const location = window.location.href;
      
      // Format message in HTML
      const message = `
        <h2>Neue Alt-gegen-Neu Anfrage</h2>
        <br/>
        <b>Kontaktdaten:</b><br/>
        Anrede: ${formData.anrede}<br/>
        Name: ${formData.name}<br/>
        E-Mail: ${formData.email}<br/>
        Telefon: ${formData.phone}<br/>
        <br/>
        <b>Golfschläger Details:</b><br/>
        Schlägertyp: ${formData.clubType}<br/>
        Hersteller: ${formData.manufacturer}<br/>
        Modell: ${formData.model}<br/>
        Zustand: ${formData.condition}<br/>
        Geschlecht: ${formData.gender}<br/>
        Händigkeit: ${formData.handedness}<br/>
        Loft: ${formData.loft}<br/>
        Schaftmaterial: ${formData.shaftMaterial}<br/>
        Schaft Flex: ${formData.shaftFlex}<br/>
        Schaftlänge: ${formData.shaftLength}<br/>
        <br/>
        <b>Seite:</b> ${location}
      `;

      await axios.post(getStrapiUrl() + "/contact", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: message,
        url: location
      });

      setFormState('success');
      
      // Reset form
      setFormData({
        anrede: '',
        name: '',
        email: '',
        phone: '',
        clubType: '',
        manufacturer: '',
        model: '',
        condition: '',
        gender: '',
        handedness: '',
        loft: '',
        shaftMaterial: '',
        shaftFlex: '',
        shaftLength: ''
      });
      
    } catch (err) {
      console.error(err);
      window.alert("Es ist ein unbekannter Fehler beim Senden passiert - bitte probieren Sie es später erneut");
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.banner}>
          <img 
            src="/AltNeuBanner.jpg" 
            alt="Alt gegen Neu - Golfschläger Upgrade Programm"
            className={styles.bannerImage + " -lg:tw-mx-[20px]"}
          />
        </div>

        <div className={styles.content}>
          <div className={styles.description}>

            <div className="tw-px-5">
              <h1 className={styles.mainTitle}>Tauschen Sie Ihre alten Golfschläger gegen Neuware</h1>
              <p>
                Bereit, Ihre Golfausrüstung aufzurüsten? Nutzen Sie die Gelegenheit, um Ihre gebrauchten 
                Golfschläger gegen brandneue Ausrüstung einzutauschen! Mit diesem Angebot können Sie Ihre 
                Spielqualität verbessern, ohne Ihr Budget zu sprengen.
              </p>
            </div>

            <div className={styles.ablaufSection}>
              <div className={styles.ablaufContent}>
                <div className={styles.ablaufHeader}>
                  <h2>Ablauf</h2>
                </div>
                <ol>
                  <li>
                    <span className={styles.punktLabel}>Punkt 1</span>
                    <p className="tw-text-white">Füllen Sie das Online-Formular mit Ihren Daten, Details und Zustand des oder der Golfschläger aus</p>
                    <div className={styles.conditionInfo}>
                      <p className="tw-text-white tw-mb-4">Der Zustand wird in folgende Teile unterteilt:</p>
                      <div className={styles.conditionGrid}>
                        <div className={styles.conditionCard}>
                          <h4>Sehr Gut</h4>
                          <p>Neuwertig ohne Gebrauchsspuren</p>
                        </div>
                        <div className={styles.conditionCard}>
                          <h4>Gut</h4>
                          <p>Minimale Gebrauchsspuren (maximaal 3-4 gespielte Golfrunden)</p>
                        </div>
                        <div className={styles.conditionCard}>
                          <h4>Befriedigend</h4>
                          <p>Gebrauchter Zustand ohne Schäden am Schlägerkopf, Schaft oder Griff (zB Skymarks, Materialabrieb am Schaft oder Griff, usw.)</p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span className={styles.punktLabel}>Punkt 2</span>
                    Sie erhalten ein Angebot per E-Mail
                  </li>
                  <li>
                    <span className={styles.punktLabel}>Punkt 3</span>
                    Wenn Sie mit dem Angebot einverstanden sind, erhalten Sie von uns ein kostenloses Versandlabel
                  </li>
                  <li>
                    <span className={styles.punktLabel}>Punkt 4</span>
                    Die Ware wird von uns geprüft. Wenn alle Angaben stimmen, erhalten Sie von uns einen Gutscheincode. Einlösbar für das gesamte Sortiment im Onlineshop.
                  </li>
                </ol>
              </div>
            </div>

            <div className={styles.examples}>
              <h2 className="tw-font-bold">Beispiele</h2>
              <div className={styles.examplesGrid}>
                <div className={styles.example}>
                  <img src="/ping_g425.jpg" alt="Ping G425 Max Driver" />
                  <h3>Ping G425 Max Driver</h3>
                  <p>Zustand: Befriedigend</p>
                  <p>Preis: 200.- Euro</p>
                </div>
                <div className={styles.example}>
                  <img src="/taylormade_stealth.jpg" alt="TaylorMade Stealth Eisenset" />
                  <h3>TaylorMade Stealth Eisenset</h3>
                  <p>7 * Eisen mit Graphitschaft</p>
                  <p>Zustand: Befriedigend</p>
                  <p>Preis: 370.- Euro</p>
                </div>
                <div className={styles.example}>
                  <img src="/scotty_cameron.jpg" alt="Scotty Cameron X11 Putter" />
                  <h3>Scotty Cameron X11 Putter</h3>
                  <p>Zustand: Befriedigend</p>
                  <p>Preis: 180.- Euro</p>
                </div>
              </div>
            </div>

            <div className={styles.formSection}>
              <div className={styles.formContainer}>
                <form onSubmit={handleSubmit} className={styles.tradeInForm}>
                  {formState === 'initial' ? (
                    <>
                      <h3 className="mb-5">Kontaktformular</h3>

                      <div className={styles.formGroup}>
                        <label>Anrede</label>
                        <select 
                          name="anrede" 
                          value={formData.anrede}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Herr">Herr</option>
                          <option value="Frau">Frau</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Vorname- und Nachname</label>
                        <input 
                          type="text" 
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required 
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label>E-Mail Adresse</label>
                        <input 
                          type="email" 
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required 
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label>Telefonnummer</label>
                        <input 
                          type="tel" 
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          required 
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label>Schlägertyp</label>
                        <select 
                          name="clubType"
                          value={formData.clubType}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Eisensatz">Eisensatz</option>
                          <option value="Driver">Driver</option>
                          <option value="Fairwayholz">Fairwayholz</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="Wedge">Wedge</option>
                          <option value="Putter">Putter</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Hersteller</label>
                        <select 
                          name="manufacturer"
                          value={formData.manufacturer}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Callaway">Callaway</option>
                          <option value="TaylorMade">TaylorMade</option>
                          <option value="Ping">Ping</option>
                          <option value="Mizuno">Mizuno</option>
                          <option value="Cleveland">Cleveland</option>
                          <option value="King Cobra">King Cobra</option>
                          <option value="Odyssey">Odyssey</option>
                          <option value="Srixon">Srixon</option>
                          <option value="Titleist">Titleist</option>
                          <option value="Wilson">Wilson</option>
                          <option value="Scotty Cameron">Scotty Cameron</option>
                          <option value="Adams">Adams</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Modellbezeichnung</label>
                        <input 
                          type="text" 
                          name="model"
                          value={formData.model}
                          onChange={handleChange}
                          required 
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label>Geschlecht</label>
                        <select 
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Herren">Herren</option>
                          <option value="Damen">Damen</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Händigkeit</label>
                        <select 
                          name="handedness"
                          value={formData.handedness}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Rechtshand">Rechtshand</option>
                          <option value="Linkshand">Linkshand</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Loft (zB 10,5° beim Driver, 5-PW bei den Eisen, usw.)</label>
                        <input 
                          type="text" 
                          name="loft"
                          value={formData.loft}
                          onChange={handleChange}
                          required 
                        />
                      </div>

                      <div className={styles.formGroup}>
                        <label>Schaftmaterial</label>
                        <select 
                          name="shaftMaterial"
                          value={formData.shaftMaterial}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Graphit">Graphit</option>
                          <option value="Stahl">Stahl</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Schaft Flex</label>
                        <select 
                          name="shaftFlex"
                          value={formData.shaftFlex}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Regular">Regular</option>
                          <option value="Senior">Senior</option>
                          <option value="Stiff">Stiff</option>
                          <option value="X-Stiff">X-Stiff</option>
                          <option value="Damen">Damen</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Schaftlänge</label>
                        <select 
                          name="shaftLength"
                          value={formData.shaftLength}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Standard">Standard</option>
                          <option value="Länger">Länger</option>
                          <option value="Kürzer">Kürzer</option>
                        </select>
                      </div>

                      <div className={styles.formGroup}>
                        <label>Zustand</label>
                        <select 
                          name="condition"
                          value={formData.condition}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Bitte wählen</option>
                          <option value="Sehr Gut">Sehr Gut</option>
                          <option value="Gut">Gut</option>
                          <option value="Befriedigend">Befriedigend</option>
                        </select>
                      </div>

                      <button type="submit" className={styles.submitButton}>
                        Anfrage senden
                      </button>
                    </>
                  ) : (
                    <h4 className="mt-2 mb-5">Ihre Anfrage wurde erfolgreich abgesendet.</h4>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AltGegenNeu;
