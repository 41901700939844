// extracted by mini-css-extract-plugin
export const container = "alt-gegen-neu-module--container--3aoTZ";
export const banner = "alt-gegen-neu-module--banner--18W6f";
export const bannerImage = "alt-gegen-neu-module--bannerImage--3En6L";
export const content = "alt-gegen-neu-module--content--1Zlya";
export const description = "alt-gegen-neu-module--description--3bo2m";
export const mainTitle = "alt-gegen-neu-module--mainTitle--1PnS1";
export const punktLabel = "alt-gegen-neu-module--punktLabel--1zp8d";
export const conditionInfo = "alt-gegen-neu-module--conditionInfo--E8fc0";
export const conditionGrid = "alt-gegen-neu-module--conditionGrid--_12Xi";
export const conditionCard = "alt-gegen-neu-module--conditionCard--1G6oT";
export const examples = "alt-gegen-neu-module--examples--3UIzt";
export const examplesGrid = "alt-gegen-neu-module--examplesGrid--1ajhV";
export const example = "alt-gegen-neu-module--example--1Vmwo";
export const ablaufSection = "alt-gegen-neu-module--ablaufSection--1N3az";
export const ablaufContent = "alt-gegen-neu-module--ablaufContent--IrgUZ";
export const ablaufHeader = "alt-gegen-neu-module--ablaufHeader--lX0oX";
export const formSection = "alt-gegen-neu-module--formSection--1Eh9k";
export const formContainer = "alt-gegen-neu-module--formContainer--3d6UG";
export const formGroup = "alt-gegen-neu-module--formGroup--3DNuG";
export const submitButton = "alt-gegen-neu-module--submitButton--31RW9";